import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";

import { Row, Col, FormGroup,Form, Label, Input,Card,CardBody, CardHeader,Button, Spinner, Badge } from "reactstrap";
import Web3InteractionEth, { convertHexToNumber, convertTokenIdQuantity } from "util/web3InteractionEth";

// Actions
import { getCollection,getSettings} from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { walletHooks } from "util/walletHooks";
import { getNetworkUrl, listArmorsNfts } from "helpers/contants";
import { postArmorsItemsActivity } from "helpers/backend_helper";

const ArmorsAirdrop = ({}) => {
    const wallet = walletHooks();
    const dispatch = useDispatch();
   const {settings} = useSelector(({Settings, collections,items})=>({
      loadingSettings : Settings.loading,
      settings: Settings.settings,
      loadingCollection : collections.loading
   }));

   const [fields,setFields] = useState({
    walletAddress : "",
    armorId : "",
    quantity : 1
   });
  const [dataList,setDataList] = useState([]);

  const [loaders,setLoaders] = useState({
    airdrop : false
  });
  const [armorsCollection,setArmorsCollection] = useState(null);

  useEffect(() => {
     dispatch(getSettings());
  }, []);

  useEffect(()=>{
      if(JSON.stringify(settings !== '{}')){
        dispatch(getCollection(settings.activeArmorsCollectionId,(data)=>{
            setArmorsCollection(data);
        }));
     
      }
  },[JSON.stringify(settings)]);


  const onHandleChange = (e) =>{
      const {value,name} = e.target;
     setFields((prev)=>({...prev,[name]:value?.trim()}));
  }

  const onAddWallet = async() =>{
    if(!fields.walletAddress){
     return toastr.error("Wallet address is required!")
    }
    if(!fields.armorId){
        return toastr.error("Armors token id is required!")
       }
       if(!fields.quantity){
        return toastr.error("Quantity is required!")
       }
   
  if(!armorsCollection?.contractAddress){
   return toastr.error("Armors contract missing please reload and try again","",{timeOut : 3000});
  }
    
    let copy = [...dataList];
    copy.push({
      walletAddress : fields.walletAddress?.toLowerCase(),
      armorId : fields.armorId,
      quantity : fields.quantity
    })
      setDataList(copy);
      setFields({
        walletAddress :"",
        armorId : "",
        quantity : 1
      });
  }

  const onRemoveWallet = (index) =>{
     setDataList((wallets)=>wallets.filter((_,idx)=> index !== idx));
  }

  const onClickAirdrop = async() =>{
    try{

      console.log("dataList",dataList)
        if(dataList?.length < 1){
            return  toastr.error("Please add token and wallet address is required!");
        }
        if(!armorsCollection?._id){
          return toastr.error("Armors collection not load or found!");
        }
    
        const web3InstanceEth = new Web3InteractionEth(
            armorsCollection.blockchain,
            window.ethereum,
            settings
          );
          console.log("switch chane before")
          await web3InstanceEth.switchChain();

          const contract = await web3InstanceEth.getContract(
            armorsCollection.contractAbi,
            armorsCollection.contractAddress
          );

         if (!contract) {
            // setloading(false);
            return toastr.error("Service not provided!");
          }
        
          const recepients = dataList.map(item=> item.walletAddress);
          const armorsTokenIds = dataList.map(item => item.armorId);
          const quantities = dataList.map(item => item.quantity);

          console.log("contract",contract)

    
          const result = await contract.callStatic["airdrop"](recepients,armorsTokenIds,quantities);

          console.log("Successfully chek : ",result);
    
          setLoaders((prev)=>({...prev,airdrop:true}))
          
        
          const transaction = await contract.airdrop(recepients,armorsTokenIds,quantities);
            const tx = await transaction.wait();
            console.log("tx", tx);
           const  txHash = tx.transactionHash;
          
           setLoaders((prev)=>({...prev,airdrop:false}))            
         
         //save item and history
        if(txHash){
          toastr.success("Nft's minted successfully!")

          const payload = await Promise.all(tx.logs.map(async (log,index) => {
            const rawAddress = log.topics[2]; // Assuming there's only one address in the logs 
            const logData = log.data;
            console.log("logData",logData)
            const {tokenId,quantity} = convertTokenIdQuantity(logData);
            console.log("tokenId",tokenId,"quantity",quantity);
            const formattedAddress = '0x' + rawAddress.slice(26);
            console.log("wallet address for transaction to", formattedAddress)
            const nftType = listArmorsNfts.find((item)=> parseInt(item.tokenId) === parseInt(tokenId)).nft_type;
              console.log("mined token",tokenId);
              return {
                collection_id: armorsCollection?._id,
                walletAddress: formattedAddress,
                token_ids: [parseInt(tokenId)],
                price: 0,
                mint_type: "armors",
                nft_type : nftType,
                transactionHash: txHash,
                type: armorsCollection.blockchain || "base",
                quantity: quantity,
                action : "mint",
              };
          }));
          console.log("payload",payload)
         
          const response  = await postArmorsItemsActivity({activities_type : "multiple", activities : payload, type: armorsCollection.blockchain || "base",});
           if(response?.status === "success"){
             toastr.success("Activity saved successfully!");
             setDataList([]);
             setFields({
              walletAddress : "",
              armorId : "",
              quantity : 1
             })
           }
        }
    }catch(error){
      console.log("Error",error);
      setLoaders((prev)=>({...prev,airdrop:false}))
      toastr.error(error?.message || "Something went wrong!");
    }
    
  }

  return (
 
            <Card>
                <CardHeader className="bg-primary text-white">
                    Armors airdrop 
                </CardHeader>
              <CardBody className="spinner-content">
                   <Form>
                          <Row>
                          <Col md={3}>
                                <FormGroup>
                                    <Label>Armor Token Id</Label>

                                    {/* <Input
                                        type="number"
                                        placeholder="Please enter armor token id"
                                        name="armorId"
                                        value={fields.armorId}
                                        onChange={onHandleChange}
                                    /> */}
                                    <select 
                                      className="custom-select w-100"
                                      placeholder="Please select armor token id" 
                                      name="armorId" 
                                      value={fields.armorId}
                                      onChange={onHandleChange}>
                                      {listArmorsNfts.map((nft)=>
                                       <option value={nft.tokenId}>
                                         {`${nft.name} (${nft.tokenId})`}
                                       </option>)}
                                    </select >
                                </FormGroup>
                            </Col>
                           <Col md={3}>
                                <FormGroup>
                                    <Label>Wallet Address</Label>

                                    <Input
                                        type="string"
                                        placeholder="Please enter wallet address"
                                        name="walletAddress"
                                        value={fields.walletAddress}
                                        onChange={onHandleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label>Quantity</Label>

                                    <Input
                                        type="number"
                                        placeholder="Please enter armor token id"
                                        name="quantity"
                                        value={fields.quantity}
                                        onChange={onHandleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                 <Button className="mt-4" onClick={onAddWallet} type="button" color="primary">
                                    Add
                                 </Button>
                                </FormGroup>
                            </Col>
                            </Row>
                            <Row>
                            <Col md={3}>
                               <p>Wallet Address</p>
                            </Col>
                            <Col md={3}>
                               <p>TokenId</p>
                            </Col>
                            <Col md={3}>
                               <p>quantity</p>
                            </Col>
                            <Col md={3}>
                               <p>Action</p>
                            </Col>
                            </Row>
                            {dataList.map((item,index)=>(
                            <Row>
                            <Col md={3}>
                                <Badge className="p-1" color="primary">{item.walletAddress}</Badge>
                            </Col>
                            <Col md={3}>
                                <Badge className="p-1" color="primary">{item.armorId}</Badge>
                            </Col>
                            <Col md={3}>
                                <Badge className="p-1" color="primary">{item.quantity}</Badge>
                            </Col>
                            <Col md={3}>
                            <FormGroup>
                                 <Button onClick={()=>onRemoveWallet(index)} type="button" color="primary">
                                    Remove
                                 </Button>
                                </FormGroup>
                            </Col>
                            </Row>
                            ))}
                            <Row>
                            <Col md={12}>
                            <FormGroup>
                                <Button disabled={loaders.airdrop} onClick={()=>onClickAirdrop()} type="button" color="primary">
                                {(loaders.airdrop) && <Spinner size={"sm"} color="light"/> } Airdrop
                                </Button>
                                </FormGroup>
                            </Col>
                            </Row>
                   </Form>
              </CardBody>
            </Card>
  );
};

export default withRouter(
    ArmorsAirdrop
);
